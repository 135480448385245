<template></template>

<script>
import { useClient, useMutation } from 'villus';
import { gql } from 'graphql-tag';
import { useStore } from 'vuex';
import { computed } from 'vue';

export default {
  name:"updateAccountMutation",

  setup() {
    const store = useStore();
    
    useClient({
      url: process.env.VUE_APP_SALEOR_URL,
      cachePolicy: 'network-only'
    });
    
    const updateAccountMutation = gql`
      mutation($firstName: String!, $lastName: String!) {
        accountUpdate(
        input: {
          firstName: $firstName
          lastName: $lastName
        }
        ) {
          accountErrors {
            field
            message
            code
          }
        }
      }
      `;
  
    const { execute: updateAccount } = useMutation(updateAccountMutation,{
      context: computed(() => store.state.data.headers)
    });
  
    store.commit('graphqlData', { item: 'updateAccount', result: updateAccount });
  },
}
</script>