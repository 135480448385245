<template>
  <div class="text-coolGray-400 text-sm font-light mb-3">{{text.downloads}}</div>
  <div class="transition duration-500 ease-in-out w-full my-1.5 bg-coolGray-900 h-16 font-stolzl font-light text-white flex flex-row justify-center items-center border-coolGray-900 cursor-pointer hover:bg-white hover:text-coolGray-900 border-2">{{text.downloadPricelist}}</div>
  <div class="transition duration-500 ease-in-out w-full my-1.5 bg-coolGray-900 h-16 font-stolzl font-light text-white flex flex-row justify-center items-center border-coolGray-900 cursor-pointer hover:bg-white hover:text-coolGray-900 border-2">{{text.downloadCatalogue}}</div>
  <div class="transition duration-500 ease-in-out w-full my-1.5 bg-coolGray-900 h-16 font-stolzl font-light text-white flex flex-row justify-center items-center border-coolGray-900 cursor-pointer hover:bg-white hover:text-coolGray-900 border-2">{{text.downloadImg}}</div>
</template>

<script>
export default {
  name: 'account_downloads',
  computed: {
    text() {
      return this.$store.state.lang.account[this.$store.state.lang.currentLang];
    },
  },
};
</script>
