<template></template>

<script>
import { useClient, useMutation } from 'villus';
import { gql } from 'graphql-tag';
import { useStore } from 'vuex';

export default {
  name:"loginMutation",

  setup() {
    const store = useStore();
    
    useClient({
      url: process.env.VUE_APP_SALEOR_URL,
      cachePolicy: 'network-only'
    });
    
    const loginMutation = gql`
      mutation($email: String!, $password: String!) {
        tokenCreate(email: $email, password: $password) {
          token
          refreshToken
          csrfToken
          errors {
            field
            message
          }
        }
      }
      `;
      
    const { execute: loginUser } = useMutation(loginMutation);
  
    store.commit('graphqlData', { item: 'loginUser', result: loginUser });
  },
}
</script>