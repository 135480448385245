<template></template>

<script>
import { useClient, useMutation } from 'villus';
import { gql } from 'graphql-tag';
import { useStore } from 'vuex';
import { computed } from 'vue';

export default {
  name:"updateAccountMetaMutation",

  setup() {
    const store = useStore();
    
    useClient({
      url: process.env.VUE_APP_SALEOR_URL,
      cachePolicy: 'network-only'
    });
    
    const updateAccountMetaMutation = gql`
      mutation($id: ID!, $key: String!, $value: String!) {
        updateMetadata(
          id: $id
          input: [{ key: $key, value: $value }]
        ) {
          metadataErrors {
            field
            message
            code
          },
          errors{
            field
            message
            code
          }
        }
      }
      `;
  
    const { execute: updateAccountMeta } = useMutation(updateAccountMetaMutation,{
      context: computed(() => store.state.data.headers)
    });
  
    store.commit('graphqlData', { item: 'updateAccountMeta', result: updateAccountMeta });
  },
}
</script>