<template></template>

<script>
import { useClient, useMutation } from 'villus';
import { gql } from 'graphql-tag';
import { useStore } from 'vuex';
import { computed } from 'vue';

export default {
  name:"updateAddressMutation",

  setup() {
    const store = useStore();
    
    useClient({
      url: process.env.VUE_APP_SALEOR_URL,
      cachePolicy: 'network-only'
    });
    
    const updateAddressMutation = gql`
      mutation($id: ID!, $firstName: String!, $lastName: String!, $companyName: String!, $streetAddress1: String!, $streetAddress2: String!, $city: String!, $cityArea: String!, $postalCode: String!, $country: CountryCode!) {
        accountAddressUpdate(
          id: $id
          input: {
            firstName: $firstName
            lastName: $lastName
            companyName: $companyName
            streetAddress1: $streetAddress1
            streetAddress2: $streetAddress2
            city: $city
            cityArea: $cityArea
            postalCode: $postalCode
            country: $country
          }
        ) {
          accountErrors {
            field
            message
            code
          }
        }
      }
      `;
  
    const { execute: updateAddress } = useMutation(updateAddressMutation,{
      context: computed(() => store.state.data.headers)
    });
  
    store.commit('graphqlData', { item: 'updateAddress', result: updateAddress });
  },
}
</script>