<script>
export default {
  name:"silentRefresh",
  methods:{
    
    silentRefreshTryAgain(tryAgain){
    console.log("siltenRefresh")
    if (window.localStorage.rewhiteSession) {
        this.refreshToken({
          CSRF: window.localStorage.rewhiteSession
        }).then(result => {
          if (result.error) {
            console.log(result.error.message);
          } else {
            var errors = result.data.tokenRefresh.accountErrors;
              if(errors.length == 0){
                this.$store.commit('setAccessToken', result.data.tokenRefresh.token);
                tryAgain.click();
              } else {
                //logout
                console.log(errors);
                this.forceLogout();
              }
            
          }
        });
    } else {
      //logout
      this.forceLogout();
    }
  },

  }
 }
</script>