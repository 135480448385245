<template></template>

<script>
import { useClient, useMutation } from 'villus';
import { gql } from 'graphql-tag';
import { useStore } from 'vuex';
import { computed } from 'vue';

export default {
  name:"changeUserPassMutation",

  setup() {
    const store = useStore();
    
    useClient({
      url: process.env.VUE_APP_SALEOR_URL,
      cachePolicy: 'network-only'
    });
    
     const changeUserPassMutation = gql`
      mutation($oldPass: String!, $newPass: String!) {
        passwordChange(oldPassword: $oldPass, newPassword: $newPass) {
          accountErrors {
            field
            code
          }
        }
      }
      `;
  
    const { execute: changeUserPass } = useMutation(changeUserPassMutation,{
      context: computed(() => store.state.data.headers)
    });
  
    store.commit('graphqlData', { item: 'changeUserPass', result: changeUserPass });
  },
}
</script>