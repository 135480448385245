<template>
  <div class="font-stolzl text-coolGray-900">
    <div class="h-screen flex flex-col">

      <div class="flex flex-row h-screen md:pt-16">

        <!-- LEFT-->
        <div class="hidden md:block w-1/2 bg-coolGray-100 h-full">
          <div v-if="auth" class="w-full h-full flex flex-col justify-center items-center font-light text-base">
            <div v-for="tab in accountTabs" :key="tab">
              <div class="transition duration-500 ease-in-out py-1.5 text-coolGray-400 cursor-pointer hover:text-coolGray-900" :class="{'text-coolGray-900' : currentTab == tab}" @click="setTab(tab)">{{tab}}</div>
            </div>
          </div>
        </div>
        <!-- LEFT-->

        <!-- RIGHT-->

        <!-- LOGIN & SIGNUP FORM -->
        <div v-if="!auth" class="w-full md:w-1/2 h-full flex flex-row justify-center md:items-center items-start">
          <div class="mt-12 md:mt-0 w-full flex flex-col justify-center items-center">
            <form id="login" @submit.prevent="submitLogin" class="w-5/6 md:w-3/4">
            <div class="text-red-500">{{text.underDevelopment}}</div>
              <div v-if="toggle == ''" class="text-xl">{{text.login}}</div>
              <div v-else-if="toggle == 'register'" class="text-xl">{{text.register}}</div>
              <div v-else-if="toggle == 'pass'" class="text-xl">{{text.resetPass}}</div>

              <div v-if="toggle == ''" class="mt-2 text-sm font-light">{{text.loginInfo}}</div>
              <div v-else-if="toggle == 'register'" class="mt-2 text-sm font-light">{{text.registerInfo}}</div>
              <div v-else-if="toggle == 'pass'" class="mt-2 text-sm font-light">{{text.passReset}}</div>

              <!-- email -->
              <div class="relative mt-5 px-5 h-16 font-stolzl font-light w-full text-coolGray-900 border-2 border-coolGray-900 flex flex-col justify-center">
                <transition @enter="showInfo" @leave="hideInfo" :css="false">
                  <div v-if="email && email !== ''" class="font-light text-coolGray-700 text-xs">{{text.email}}</div>
                </transition>
                <input id="email" name="email" v-model="email" type="email" :placeholder="text.email" class="w-full font-light appearance-none outline-none placeholder-coolGray-900 flex flex-row justify-center items-center" required/>
              </div>

              <!-- pass -->
              <div v-if="toggle !== 'pass'" class="relative mt-3 mr-1.5 px-5 h-16 font-stolzl font-light w-full text-coolGray-900 border-2 border-coolGray-900 flex flex-col justify-center">
                <transition @enter="showInfo" @leave="hideInfo" :css="false">
                  <div v-if="pass && pass !== ''" class="font-light text-coolGray-700 text-xs">{{text.pass}}</div>
                </transition>
                <input id="pass" name="pass" v-model="pass" type="password" :placeholder="text.pass" class="w-full font-light appearance-none outline-none placeholder-coolGray-900 flex flex-row justify-center items-center" required/>
              </div>

              <div v-if="errors">
                <div v-for="error in errors" :key="error" class="mt-2 text-sm text-red-600 font-light">
                  {{error.code}}
                </div>
              </div>

              <input v-if="toggle == ''" type="submit" :value="text.login2" class="mt-3 transition duration-500 ease-in-out w-full bg-coolGray-800 h-16 font-stolzl font-light text-white flex flex-row justify-center items-center cursor-pointer md:hover:bg-white md:hover:text-coolGray-900 md:hover:border-2 md:hover:border-coolGray-900"/>
              <input v-else-if="toggle == 'register'" type="submit" :value="text.register" class="mt-3 transition duration-500 ease-in-out w-full bg-coolGray-800 h-16 font-stolzl font-light text-white flex flex-row justify-center items-center cursor-pointer md:hover:bg-white md:hover:text-coolGray-900 md:hover:border-2 md:hover:border-coolGray-900"/>
              <input v-else-if="toggle == 'pass'" type="submit" :value="text.resetPass" class="mt-3 transition duration-500 ease-in-out w-full bg-coolGray-800 h-16 font-stolzl font-light text-white flex flex-row justify-center items-center cursor-pointer md:hover:bg-white md:hover:text-coolGray-900 md:hover:border-2 md:hover:border-coolGray-900"/>
            </form>

            <div class="w-5/6 md:w-3/4">
              <div v-if="loginError?.length > 0" class="text-red-600 text-sm font-light mt-2">
                {{loginError}}
              </div>
              <div v-if="toggle == ''" class="mt-2 text-sm font-light cursor-pointer flex flex-col md:flex-row justify-between">
                <div @click="toggle = 'register'">{{text.new}}</div>
                <div class="mt-3 md:mt-0" @click="toggle = 'pass'">{{text.forgotPass}}</div>
              </div>
              <div v-else class="mt-2 text-sm font-light cursor-pointer" @click="toggle = ''">{{text.back}}</div>
            </div>
          </div>
        </div>
        <!-- LOGIN & SIGNUP FORM -->

        <!-- USER DATA -->
        <div v-else class="w-full md:w-1/2 h-full flex flex-row justify-center md:items-center items-start">

          <!-- ACCOUNT DETAILS -->
          <div v-if="currentTab == 'Account'" class="mt-12 md:mt-0 w-full flex flex-col justify-center items-center">
            <div v-if="currentEdit == '' || currentEdit == 'AccountDetails' || currentEdit == 'userEmail'" class="flex flex-col w-5/6 md:w-3/4">
              <div class="text-coolGray-400 text-sm font-light">{{text.email}}</div>
              <div v-if="userDetails?.email" class="font-light text-base">{{userDetails.email}}</div>
            </div>
            <div v-if="!editMode" class="flex flex-col w-5/6 md:w-3/4 mt-5">
              <div class="text-coolGray-400 text-sm font-light">{{text.cc}}</div>
              <div v-if="userDetails?.metadata?.some(e => e.key === 'cc')" class="font-light text-base">{{findMeta(userDetails.metadata,'cc')}}</div>
              <div v-else class="font-light text-base">-</div>
            </div>
            <div v-if="!editMode" class="flex flex-row w-5/6 md:w-3/4 justify-between my-5">
              <div class="w-full">
                <div class="text-coolGray-400 text-sm font-light">{{text.name}}</div>
                <div v-if="userDetails?.firstName" class="font-light text-base">{{userDetails.firstName}}</div>
                <div v-else class="font-light text-base">-</div>
              </div>
              <div v-if="!editMode" class="w-full">
                <div class="text-coolGray-400 text-sm font-light">{{text.lastName}}</div>
                <div v-if="userDetails?.lastName" class="font-light text-base">{{userDetails.lastName}}</div>
                <div v-else class="font-light text-base">-</div>
              </div>
            </div>

            <!-- UPDATE ACCOUNT DETAILS -->
            <div v-if="editMode && currentEdit == 'AccountDetails'" class="w-5/6 md:w-3/4">
              <form id="data01" @submit.prevent="submitAccountData">
                <div class="relative mt-3 px-5 h-16 font-stolzl font-light w-full text-coolGray-900 border-2 border-coolGray-900 flex flex-col justify-center">
                  <transition @enter="showInfo" @leave="hideInfo" :css="false">
                    <div v-if="cc && cc !== ''" class="font-light text-coolGray-700 text-xs">{{text.cc}}</div>
                  </transition>
                  <input id="cc" name="cc" v-model="cc" type="email" :placeholder="text.cc" class="w-full font-light appearance-none outline-none placeholder-coolGray-900 flex flex-row justify-center items-center"/>
                </div>

                <div class="relative mt-3 px-5 h-16 font-stolzl font-light w-full text-coolGray-900 border-2 border-coolGray-900 flex flex-col justify-center">
                  <transition @enter="showInfo" @leave="hideInfo" :css="false">
                    <div v-if="vorname && vorname !== ''" class="font-light text-coolGray-700 text-xs">{{text.name}}</div>
                  </transition>
                  <input id="vorname" name="vorname" v-model="vorname" type="text" :placeholder="text.name" class="w-full font-light appearance-none outline-none placeholder-coolGray-900 flex flex-row justify-center items-center"/>
                </div>

                <div class="relative mt-3 px-5 h-16 font-stolzl font-light w-full text-coolGray-900 border-2 border-coolGray-900 flex flex-col justify-center">
                  <transition @enter="showInfo" @leave="hideInfo" :css="false">
                    <div v-if="nachname && nachname !== ''" class="font-light text-coolGray-700 text-xs">{{text.lastName}}</div>
                  </transition>
                  <input id="nachname" name="nachname" v-model="nachname" type="text" :placeholder="text.lastName" class="w-full font-light appearance-none outline-none placeholder-coolGray-900 flex flex-row justify-center items-center"/>
                </div>

                <input id="updateAccount" type="submit" :value="text.save" class="transition duration-500 ease-in-out w-full mt-3 bg-coolGray-900 h-16 font-stolzl font-light text-white flex flex-row justify-center items-center border-coolGray-900 cursor-pointer hover:bg-white hover:text-coolGray-900 border-2"/>
                <div v-if="accountDetailErrors?.length > 0" class="font-light text-base text-red-600">
                  {{accountDetailErrors}}
                </div>
              </form>

              <div class="font-stolzl flex flex-row justify-start items-center mt-2 -ml-1 group cursor-pointer" @click="editMode = false; currentEdit = ''">
                <svg xmlns="http://www.w3.org/2000/svg" class="transition duration-200 ease-out h-4 w-4 text-coolGray-700 transform group-hover:text-coolGray-900 group-hover:-translate-x-1" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 19l-7-7 7-7" />
                </svg>
                <div class="transition duration-200 ease-out font-light text-base md:text-sm mt-0.5 text-coolGray-700 group-hover:text-coolGray-900 ml-0.5">{{text.back2}}</div>
              </div>

            </div>
            <!-- UPDATE ACCOUNT DETAILS -->

            <!-- CHANGE USER EMAIL -->
            <div v-if="editMode && currentEdit == 'userEmail'" class="w-5/6 md:w-3/4">

              <form id="data02" @submit.prevent="submitEmailChange">

                <!-- new email -->
                <div class="relative mt-3 px-5 h-16 font-stolzl font-light w-full text-coolGray-900 border-2 border-coolGray-900 flex flex-col justify-center">
                  <transition @enter="showInfo" @leave="hideInfo" :css="false">
                    <div v-if="newEmail && newEmail !== ''" class="font-light text-coolGray-700 text-xs">{{text.newEmail}}</div>
                  </transition>
                  <input id="newEmail" name="newEmail" v-model="newEmail" type="email" :placeholder="text.newEmail" class="w-full font-light appearance-none outline-none placeholder-coolGray-900 flex flex-row justify-center items-center" required/>
                </div>

                <!-- pass confirmation -->
                <div class="relative mt-3 mr-1.5 px-5 h-16 font-stolzl font-light w-full text-coolGray-900 border-2 border-coolGray-900 flex flex-col justify-center">
                  <transition @enter="showInfo" @leave="hideInfo" :css="false">
                    <div v-if="passConf && passConf !== ''" class="font-light text-coolGray-700 text-xs">{{text.passAgain}}</div>
                  </transition>
                  <input id="passConf" name="passConf" v-model="passConf" type="password" :placeholder="text.passAgain" class="w-full font-light appearance-none outline-none placeholder-coolGray-900 flex flex-row justify-center items-center" required/>
                </div>

                <input id="changeEmail" type="submit" :value="text.changeEmail" class="transition duration-500 ease-in-out w-full mt-3 bg-coolGray-900 h-16 font-stolzl font-light text-white flex flex-row justify-center items-center border-coolGray-900 cursor-pointer hover:bg-white hover:text-coolGray-900 border-2"/>

                <div v-if="submitEmailErrors?.length > 0" class="font-light text-base text-red-600">
                  {{submitEmailErrors}}
                </div>

              </form>

              <div class="font-stolzl flex flex-row justify-start items-center mt-2 -ml-1 group cursor-pointer" @click="editMode = false; currentEdit = ''">
                <svg xmlns="http://www.w3.org/2000/svg" class="transition duration-200 ease-out h-4 w-4 text-coolGray-700 transform group-hover:text-coolGray-900 group-hover:-translate-x-1" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 19l-7-7 7-7" />
                </svg>
                <div class="transition duration-200 ease-out font-light text-base md:text-sm text-coolGray-700 group-hover:text-coolGray-900 mt-0.5 ml-0.5">{{text.back2}}</div>
              </div>

            </div>
            <!-- CHANGE USER EMAIL -->

            <!-- CHANGE USER PASS -->
            <div v-if="editMode && currentEdit == 'newPass'" class="w-5/6 md:w-3/4">

              <form id="data03" @submit.prevent="submitPassChange">

                <!-- old pass -->
                <div class="relative mt-3 px-5 h-16 font-stolzl font-light w-full text-coolGray-900 border-2 border-coolGray-900 flex flex-col justify-center">
                  <transition @enter="showInfo" @leave="hideInfo" :css="false">
                    <div v-if="oldPass && oldPass !== ''" class="font-light text-coolGray-700 text-xs">{{text.oldPass}}</div>
                  </transition>
                  <input id="oldPass" name="oldPass" v-model="oldPass" type="password" :placeholder="text.oldPass" class="w-full font-light appearance-none outline-none placeholder-coolGray-900 flex flex-row justify-center items-center" required/>
                </div>

                <!-- new pass -->
                <div class="relative mt-3 mr-1.5 px-5 h-16 font-stolzl font-light w-full text-coolGray-900 border-2 border-coolGray-900 flex flex-col justify-center">
                  <transition @enter="showInfo" @leave="hideInfo" :css="false">
                    <div v-if="newPass && newPass !== ''" class="font-light text-coolGray-700 text-xs">{{text.newPass}}</div>
                  </transition>
                  <input id="newPass" name="newPass" v-model="newPass" type="password" :placeholder="text.newPass" class="w-full font-light appearance-none outline-none placeholder-coolGray-900 flex flex-row justify-center items-center" required/>
                </div>

                <input id="passChange" type="submit" :value="text.changePass" class="transition duration-500 ease-in-out w-full mt-3 bg-coolGray-900 h-16 font-stolzl font-light text-white flex flex-row justify-center items-center border-coolGray-900 cursor-pointer hover:bg-white hover:text-coolGray-900 border-2"/>

                <div v-if="passChangeErrors?.length > 0" class="font-light text-base text-red-600">
                  {{passChangeErrors}}
                </div>

              </form>

              <div class="font-stolzl flex flex-row justify-start items-center mt-2 -ml-1 group cursor-pointer" @click="editMode = false; currentEdit = ''">
                <svg xmlns="http://www.w3.org/2000/svg" class="transition duration-200 ease-out h-4 w-4 text-coolGray-700 transform group-hover:text-coolGray-900 group-hover:-translate-x-1" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 19l-7-7 7-7" />
                </svg>
                <div class="transition duration-200 ease-out font-light text-base md:text-sm text-coolGray-700 group-hover:text-coolGray-900 mt-0.5 ml-0.5">{{text.back2}}</div>
              </div>

            </div>
            <!-- CHANGE USER PASS -->

            <div v-if="!editMode" class="transition duration-500 ease-in-out w-5/6 md:w-3/4 my-1.5 bg-coolGray-900 h-16 font-stolzl font-light text-white flex flex-row justify-center items-center border-coolGray-900 cursor-pointer md:hover:bg-white md:hover:text-coolGray-900 border-2" @click="editMode = true; currentEdit = 'AccountDetails'">{{text.changeDetails}}</div>
            <div v-if="!editMode" class="transition duration-500 ease-in-out w-5/6 md:w-3/4 my-1.5 bg-coolGray-900 h-16 font-stolzl font-light text-white flex flex-row justify-center items-center border-coolGray-900 cursor-pointer md:hover:bg-white md:hover:text-coolGray-900 border-2" @click="editMode = true; currentEdit = 'userEmail'">{{text.changeEmail}}</div>
            <div v-if="!editMode" class="transition duration-500 ease-in-out w-5/6 md:w-3/4 my-1.5 bg-coolGray-900 h-16 font-stolzl font-light text-white flex flex-row justify-center items-center border-coolGray-900 cursor-pointer md:hover:bg-white md:hover:text-coolGray-900 border-2" @click="editMode = true; currentEdit = 'newPass'">{{text.changePass}}</div>
            <div id="deleteAccount" v-if="!editMode" class="transition duration-500 ease-in-out w-5/6 md:w-3/4 my-1.5 bg-coolGray-900 h-16 font-stolzl font-light text-white flex flex-row justify-center items-center border-coolGray-900 cursor-pointer md:hover:bg-white md:hover:text-coolGray-900 border-2" @click="submitDeleteAccount()">{{text.deleteAccount}}</div>
            <div v-if="accountDeleteErrors?.length > 0" class="font-light text-base text-red-600 w-5/6 md:w-3/4">
                  {{accountDeleteErrors}}
                </div>
            <div id="logout" v-if="!editMode" class="w-5/6 md:w-3/4 font-stolzl flex flex-row justify-start items-center mt-2 -ml-1 group cursor-pointer" @click="cleanLogout()">
                <svg xmlns="http://www.w3.org/2000/svg" class="transition duration-200 ease-out h-4 w-4 text-coolGray-700 transform md:group-hover:text-coolGray-900 md:group-hover:-translate-x-1" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 19l-7-7 7-7" />
                </svg>
                <div class="transition duration-200 ease-out font-light text-base md:text-sm text-coolGray-700 group-hover:text-coolGray-900 mt-0.5 ml-0.5">{{text.logout}}</div>
              </div>

          </div>

          <!-- USER DETAILS -->
          <div v-if="currentTab == 'Kontaktdaten'" class="mt-12 md:mt-0 w-5/6 md:w-3/4 flex flex-col">
           <accountUserDetails />
          </div>

          <!-- Order Details -->
          <div v-else-if="currentTab == 'Bestellungen'" class="mt-12 md:mt-0 w-5/6 md:w-3/4 flex flex-col">
            <accountOrderDetails />
          </div>

          <!-- DOWNLOADS -->
          <div v-else-if="currentTab == 'Downloads'" class="mt-12 md:mt-0 w-5/6 md:w-3/4 flex flex-col">
          <accountDownloads />
          </div>
        </div>
        <!-- USER DATA -->

        <!-- RIGHT-->
      </div>

      </div>
    <!-- COMPONENTS-->
    <accountRegisterMutation />
    <changeUserEmailMutation />
    <changeUserPassMutation />

    <deleteAccountMutation />
    <loginMutation />
    <logoutMutation />
    <refreshTokenMutation />
    <resetUserPassMutation />
    <updateAccountMetaMutation />
    <updateAccountMutation />

    <userDetailsQuery />
    <Footer />
  </div>
</template>

<script>
import Footer from '../components/footer.vue';

// Mixins
import formatPrice from '../mixins/formatPrice.vue';
import showHide from '../mixins/showHide.vue';
import scrollToTop from '../mixins/scrollToTop.vue';

// GraphQL Components
import accountRegisterMutation from '../graphql/saleor/accountRegisterMutation.vue';
import changeUserEmailMutation from '../graphql/saleor/changeUserEmailMutation.vue';
import changeUserPassMutation from '../graphql/saleor/changeUserPassMutation.vue';
import deleteAccountMutation from '../graphql/saleor/deleteAccountMutation.vue';
import loginMutation from '../graphql/saleor/loginMutation.vue';
import logoutMutation from '../graphql/saleor/logoutMutation.vue';
import refreshTokenMutation from '../graphql/saleor/refreshTokenMutation.vue';
import resetUserPassMutation from '../graphql/saleor/resetUserPassMutation.vue';
import updateAccountMetaMutation from '../graphql/saleor/updateAccountMetaMutation.vue';
import updateAccountMutation from '../graphql/saleor/updateAccountMutation.vue';
import userDetailsQuery from '../graphql/saleor/userDetailsQuery.vue';

// Components
import accountUserDetails from '../components/account_userDetails.vue';
import accountDownloads from '../components/account_downloads.vue';
import accountOrderDetails from '../components/account_orderDetails.vue';

/* TODO ACCOUNT

- Bestellungen Rechnung herunterladen
- Bestellungen erneut in den Warenkorb
- Download Links
*/

export default {
  name: 'Account',

  components: {
    Footer,
    accountRegisterMutation,
    changeUserEmailMutation,
    changeUserPassMutation,
    deleteAccountMutation,
    loginMutation,
    logoutMutation,
    refreshTokenMutation,
    resetUserPassMutation,
    updateAccountMetaMutation,
    updateAccountMutation,
    userDetailsQuery,
    accountUserDetails,
    accountDownloads,
    accountOrderDetails,
  },
  mixins: [formatPrice, showHide, scrollToTop],

  data() {
    return {
      // login, signup, reset
      toggle: '',
      email: null,
      pass: null,
      loginError: null,

      // account delete
      accountDeleteErrors: null,

      errors: null,
      accountDetailErrors: null,
      editMode: false,
      currentEdit: '',

      // change account details
      cc: null,
      vorname: null,
      nachname: null,

      // change email
      newEmail: null,
      passConf: null,
      submitEmailErrors: null,

      // change pass
      oldPass: null,
      newPass: null,
      passChangeErrors: null,

      // update shipping address
      addressUpdateErrors: null,
      shippingFirstName: null,
      shippingLastName: null,
      shippingCompanyName: null,
      shippingStreetAddress1: null,
      shippingStreetAddress2: null,
      shippingPostalCode: null,
      shippingCity: null,
      shippingCityArea: null,
      shippingCountry: null,

      // update billing address
      billingFirstName: null,
      billingLastName: null,
      billingCompanyName: null,
      billingStreetAddress1: null,
      billingStreetAddress2: null,
      billingPostalCode: null,
      billingCity: null,
      billingCityArea: null,
      billingCountry: null,

    };
  },

  computed: {
    // graphql
    registerAccount() {
      return this.$store.state.data.registerAccount;
    },
    loginUser() {
      return this.$store.state.data.loginUser;
    },
    logoutUser() {
      return this.$store.state.data.logoutUser;
    },
    userDetails() {
      return this.$store.state.data.userDetails?.me;
    },
    getUserDetails() {
      return this.$store.state.data.getUserDetails;
    },
    updateAccount() {
      return this.$store.state.data.updateAccount;
    },
    updateAccountMeta() {
      return this.$store.state.data.updateAccountMeta;
    },
    changeUserEmail() {
      return this.$store.state.data.changeUserEmail;
    },
    changeUserPass() {
      return this.$store.state.data.changeUserPass;
    },
    resetPass() {
      return this.$store.state.data.resetPass;
    },
    deleteAccount() {
      return this.$store.state.data.deleteAccount;
    },

    // data
    text() {
      return this.$store.state.lang.account[this.$store.state.lang.currentLang];
    },

    accountTabs() {
      return this.$store.state.data.accountTabs;
    },
    currentTab() {
      return this.$store.state.data.currentTab;
    },

    headers() {
      return this.$store.state.data.headers;
    },
    auth() {
      return this.$store.state.data.auth;
    },
    accessToken() {
      return this.$store.state.data.accessToken;
    },
    lang() {
      return this.$store.state.lang.currentLang;
    },
  },

  watch: {
    userDetails() {
      console.log('updating');
      const preCC = this.findMeta(this.userDetails.metadata, 'cc');
      if (preCC !== '-') {
        this.cc = preCC;
      } else {
        this.cc = '';
      }
      this.vorname = this.userDetails.firstName;
      this.nachname = this.userDetails.lastName;
      if (this.userDetails.defaultShippingAddress) {
        this.shippingFirstName = this.userDetails.defaultShippingAddress.firstName;
        this.shippingLastName = this.userDetails.defaultShippingAddress.lastName;
        this.shippingCompanyName = this.userDetails.defaultShippingAddress.companyName;
        this.shippingStreetAddress1 = this.userDetails.defaultShippingAddress.streetAddress1;
        this.shippingStreetAddress2 = this.userDetails.defaultShippingAddress.streetAddress2;
        this.shippingPostalCode = this.userDetails.defaultShippingAddress.postalCode;
        this.shippingCity = this.userDetails.defaultShippingAddress.city;
        this.shippingCityArea = this.userDetails.defaultShippingAddress.cityArea;
        this.shippingCountry = this.userDetails.defaultShippingAddress.country.code;
      }
      if (this.userDetails.defaultBillingAddress) {
        this.billingFirstName = this.userDetails.defaultBillingAddress.firstName;
        this.billingLastName = this.userDetails.defaultBillingAddress.lastName;
        this.billingCompanyName = this.userDetails.defaultBillingAddress.companyName;
        this.billingStreetAddress1 = this.userDetails.defaultBillingAddress.streetAddress1;
        this.billingStreetAddress2 = this.userDetails.defaultBillingAddress.streetAddress2;
        this.billingPostalCode = this.userDetails.defaultBillingAddress.postalCode;
        this.billingCity = this.userDetails.defaultBillingAddress.city;
        this.billingCityArea = this.userDetails.defaultBillingAddress.cityArea;
        this.billingCountry = this.userDetails.defaultBillingAddress.country.code;
      }
    },
    auth() {
      if (this.auth) {
        this.getUserDetails();
      }
    },
  },

  methods: {

    // ----------------------------------------------//
    // ------------------METHODS---------------------//
    // ----------------------------------------------//

    setTab(tab) {
      this.$store.commit('setCurrentTab', tab);
    },

    calculateQty(lines) {
      let totalQty = 0;
      // eslint-disable-next-line no-return-assign
      lines.forEach((e) => totalQty += e.quantity);
      return totalQty;
    },

    formatDate(date) {
      const d = new Date(date);
      const options = { year: 'numeric', month: 'long', day: '2-digit' };
      const formattedDate = d.toLocaleDateString('de-DE', options);
      return formattedDate;
    },

    findMeta(array) {
      const i = array.findIndex((e) => e.key === 'cc');
      let result = '-';
      if (i !== -1) {
        if (array[i].value !== '') {
          result = array[i].value;
        }
      }
      return result;
    },

    submitDeleteAccount() {
      this.deleteAccount({
        redirectUrl: `${process.env.VUE_APP_REDIRECT}confirm/deleteAccount`,
      }).then((result) => {
        if (result.error) {
          console.log(result.error.message);
          if (result.error.message.includes('expired')) {
            const el = document.getElementById('deleteAccount');
            if (el) {
              this.silentRefreshTryAgain(el);
            } else {
              this.forceLogout();
            }
          } else {
            this.accountDeleteErrors = result.error;
          }
        } else {
          const errors = result.data.accountRequestDeletion.accountErrors;
          if (errors.length === 0) {
            this.accountDeleteErrors = this.text.confirmEmail;
          } else {
            this.accountDeleteErrors = errors;
          }
        }
      });
    },

    submitPassChange() {
      this.changeUserPass({
        oldPass: this.oldPass,
        newPass: this.newPass,
      }).then((result) => {
        if (result.error) {
          console.log(result.error.message);
          if (result.error.message.includes('expired')) {
            const el = document.getElementById('passChange');
            if (el) {
              this.silentRefreshTryAgain(el);
            } else {
              this.forceLogout();
            }
          } else {
            this.passChangeErrors = result.error;
          }
        } else {
          const errors = result.data.passwordChange.accountErrors;
          if (errors.length === 0) {
            console.log('Password changed');
            this.oldPass = null;
            this.newPass = null;
            this.cleanLogout();
          } else {
            this.passChangeErrors = errors;
          }
        }
      });
    },

    cleanLogout() {
      this.logoutUser()
        .then((result) => {
          if (result.error) {
            console.log(result.error.message);
            if (result.error.message.includes('expired')) {
              const el = document.getElementById('logout');
              if (el) {
                this.silentRefreshTryAgain(el);
              } else {
                this.forceLogout();
              }
            }
          } else {
            const errors = result.data.tokensDeactivateAll.accountErrors;
            if (errors.length === 0) {
              this.forceLogout();
            } else {
              console.log('Error logging out');
              console.log(errors);
            }
          }
        });
    },

    submitEmailChange() {
      this.changeUserEmail({
        newEmail: this.newEmail.toLowerCase(),
        pass: this.passConf,
        redirectUrl: `${process.env.VUE_APP_REDIRECT}confirm/changeEmail`,
      }).then((result) => {
        if (result.error) {
          console.log(result.error.message);
          if (result.error.message.includes('expired')) {
            const el = document.getElementById('changeEmail');
            if (el) {
              this.silentRefreshTryAgain(el);
            } else {
              this.forceLogout();
            }
          } else {
            this.submitEmailErrors = result.error;
          }
        } else {
          const errors = result.data.requestEmailChange.accountErrors;
          if (errors.length === 0) {
            this.submitEmailErrors = this.text.confirmEmail2;
          } else {
            this.submitEmailErrors = errors;
          }
        }
      });
    },

    submitAccountData() {
      this.updateAccount({
        firstName: this.vorname || '',
        lastName: this.nachname || '',
      }).then((result) => {
        if (result.error) {
          console.log(result.error.message);
          if (result.error.message.includes('expired')) {
            const el = document.getElementById('updateAccount');
            if (el) {
              this.silentRefreshTryAgain(el);
            } else {
              this.forceLogout();
            }
          } else {
            this.accountDetailErrors = result.error;
          }
        } else {
          const errors = result.data.accountUpdate.accountErrors;
          if (errors.length === 0) {
            this.editMode = false;
            this.currentEdit = '';
            this.accountDetailErrors = '';
            this.getUserDetails();
          } else {
            this.accountDetailErrors = errors;
          }
        }
      });

      this.updateAccountMeta({
        id: this.userDetails.id,
        key: 'cc',
        value: this.cc || '',
      }).then((result) => {
        if (result.error) {
          console.log(result.error.message);
          if (result.error.message.includes('expired')) {
            const el = document.getElementById('updateAccount');
            if (el) {
              this.silentRefreshTryAgain(el);
            } else {
              this.forceLogout();
            }
          } else {
            this.accountDetailErrors = result.error;
          }
        } else {
          const errors = result.data.updateMetadata.metadataErrors;
          if (errors.length === 0) {
            this.editMode = false;
            this.currentEdit = '';
            this.accountDetailErrors = '';
            this.getUserDetails();
          } else {
            this.accountDetailErrors = errors;
          }
        }
      });
    },

    submitLogin() {
      console.log(this.toggle);
      if (this.toggle === '') {
        this.loginUser({
          email: this.email.toLowerCase(),
          password: this.pass,
        }).then((result) => {
          if (result.error) {
            this.loginError = result.error.message;
          } else {
            console.log(result.data);
            const { errors } = result.data.tokenCreate;
            if (errors.length === 0) {
              this.$store.commit('setCSRF', result.data.tokenCreate.csrfToken);
              this.$store.commit('setUserAuthStatus', true);
              this.$store.commit('setAccessToken', result.data.tokenCreate.token);
              this.$store.commit('setChannel', 'b2b');
            } else {
              this.loginError = errors;
            }
          }
        });
      } else if (this.toggle === 'register') {
        this.registerAccount({
          email: this.email.toLowerCase(),
          password: this.pass,
          languageCode: this.lang,
          redirectUrl: `${process.env.VUE_APP_REDIRECT}confirm/signup`,
        }).then((result) => {
          if (result.error) {
            this.loginError = result.error.message;
          } else {
            const errors = result.data.accountRegister.accountErrors;
            if (errors.length === 0) {
              this.loginError = this.text.confirmEmail3;
            } else {
              this.loginError = errors;
            }
          }
        });
      } else if (this.toggle === 'pass') {
        this.resetPass({
          email: this.email.toLowerCase(),
          redirectUrl: `${process.env.VUE_APP_REDIRECT}confirm/resetPass`,
        }).then((result) => {
          if (result.error) {
            this.loginError = result.error.message;
          } else {
            const errors = result.data.requestPasswordReset.accountErrors;
            if (errors.length === 0) {
              this.loginError = this.text.confirmEmail4;
            } else {
              this.loginError = errors;
            }
          }
        });
      }
    },

  },
};

</script>
