<template></template>

<script>
import { useQuery, useClient } from 'villus';
import { gql } from 'graphql-tag';
import { useStore } from 'vuex';
import { computed, reactive } from 'vue';

export default {
  name:"userDetailsQuery",
  
  watch: {
    userDetails() {
      this.$store.commit('graphqlData', { item: 'userDetails', result: this.userDetails });
     },
  },
    
  setup() {
    const store = useStore();

    useClient({
      url: process.env.VUE_APP_SALEOR_URL,
      cachePolicy: 'network-only'
    });

    const userDetailsQuery = gql`
       query {
          me{
            id
            email
            firstName
            lastName
            orders(first: 10){
              edges{
                node {
                  created
                  lines{
                    quantity
                  }
                  total{
                    gross{
                      amount
                    }
                  }
                }
              }
            }
            metadata{
              key
              value
            }
            defaultBillingAddress{
              id
              firstName
              lastName
              companyName
              streetAddress1
              streetAddress2
              city
              cityArea
              postalCode
              country {
                code
              }
              countryArea
            }
            defaultShippingAddress{
              id
              firstName
              lastName
              companyName
              streetAddress1
              streetAddress2
              city
              cityArea
              postalCode
              country {
                code
              }
              countryArea
            }
          }
        }
      `;
      
    const { data: userDetails, execute: getUserDetails } = useQuery({
      query: userDetailsQuery,
      fetchOnMount: false,
      context: computed(() => store.state.data.headers)
    });
    
    store.commit('graphqlData', { item: 'getUserDetails', result: getUserDetails });

    return { userDetails };
  },
}
</script>