<template></template>

<script>
import { useClient, useMutation } from 'villus';
import { gql } from 'graphql-tag';
import { useStore } from 'vuex';
import { computed } from 'vue';

export default {
  name:"resetUserPassMutation",

  setup() {
    const store = useStore();
    
    useClient({
      url: process.env.VUE_APP_SALEOR_URL,
      cachePolicy: 'network-only'
    });
    
    const resetUserPassMutation = gql`
      mutation($email: String!, $redirectUrl: String!) {
        requestPasswordReset(
          email: $email
          redirectUrl: $redirectUrl
          channel: "b2b"
        ) {
          accountErrors {
            field
            code
          }
        }
      }
      `;
  
    const { execute: resetPass } = useMutation(resetUserPassMutation,{
      context: computed(() => store.state.data.headers)
    });
  
    store.commit('graphqlData', { item: 'resetPass', result: resetPass });
  },
}
</script>