<script>
export default {
  name:"forceLogout",
  methods:{
    
    forceLogout(){
      console.log("User is logged out");
      this.$store.commit('setUserAuthStatus', false);
      this.$store.commit('setAccessToken', null);
    },

  }
 }
</script>