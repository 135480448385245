<template>
  <div class="text-coolGray-400 text-sm font-light mb-3">{{text.lastOrder}}</div>
  <div v-if="userDetails?.orders?.edges?.length > 0">
    <div v-for="(order,index) in userDetails.orders.edges" :key="order" class="flex flex-row justify-between items-center font-light text-base w-full">
      <div>{{index + 1}}.</div>
      <div>{{formatDate(order.node.created)}}</div>
      <div>{{calculateQty(order.node.lines)}} {{text.products}}</div>
      <div>{{formatPrice(order.node.total.gross.amount)}}</div>

      <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 cursor-pointer text-coolGray-900" fill="none" viewBox="0 0 24 24" stroke="currentColor">
        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M10 6H6a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2v-4M14 4h6m0 0v6m0-6L10 14" />
      </svg>

    </div>
  </div>
</template>

<script>
export default {
  name: 'account_orderDetails',
  computed: {
    text() {
      return this.$store.state.lang.account[this.$store.state.lang.currentLang];
    },
  },
};
</script>
