<template>

  <!-- Adressen -->
  <div v-if="!editMode">

    <!-- Lieferadresse -->
    <div>
      <div class="text-coolGray-400 text-sm font-light">{{text.shipping}}</div>
      <div v-if="userDetails?.defaultShippingAddress" class="font-light text-base">
        <div class="flex flex-row">
          <div class="mr-2">{{userDetails?.defaultShippingAddress?.firstName}}</div>
          <div>{{userDetails?.defaultShippingAddress?.lastName}}</div>
        </div>
        <div>{{userDetails?.defaultShippingAddress?.companyName}}</div>
        <div>{{userDetails?.defaultShippingAddress?.streetAddress1}}</div>
        <div>{{userDetails?.defaultShippingAddress?.streetAddress2}}</div>
        <div>{{userDetails?.defaultShippingAddress?.postalCode}}</div>
        <div>{{userDetails?.defaultShippingAddress?.city}}</div>
        <div>{{userDetails?.defaultShippingAddress?.cityArea}}</div>
        <div>{{getCountryName(userDetails?.defaultShippingAddress?.country?.code)}}</div>
      </div>
      <div v-else class="font-light text-base">-</div>
    </div>

    <!-- Rechnungsadresse -->
    <div class="mt-5">
      <div class="text-coolGray-400 text-sm font-light">{{text.billing}}</div>
      <div v-if="userDetails?.defaultBillingAddress" class="font-light text-base">
        <div class="flex flex-row">
          <div class="mr-2">{{userDetails?.defaultBillingAddress?.firstName}}</div>
          <div>{{userDetails?.defaultBillingAddress?.lastName}}</div>
        </div>
        <div>{{userDetails?.defaultBillingAddress?.companyName}}</div>
        <div>{{userDetails?.defaultBillingAddress?.streetAddress1}}</div>
        <div>{{userDetails?.defaultBillingAddress?.streetAddress2}}</div>
        <div>{{userDetails?.defaultBillingAddress?.postalCode}}</div>
        <div>{{userDetails?.defaultBillingAddress?.city}}</div>
        <div>{{userDetails?.defaultBillingAddress?.cityArea}}</div>
        <div>{{getCountryName(userDetails?.defaultBillingAddress?.country?.code)}}</div>
      </div>
      <div v-else class="font-light text-base">-</div>
    </div>

  </div>

  <!-- Lieferadresse Formular -->
  <div v-if="editMode && currentEdit == 'shippingAddress'" class="w-full">

    <form id="data04" @submit.prevent="submitUpdateShipping">

      <div class="flex flex-row">
        <!-- firstName -->
        <div class="relative mr-3 mt-3 px-5 h-16 font-stolzl font-light w-full text-coolGray-900 border-2 border-coolGray-900 flex flex-col justify-center">
          <transition @enter="showInfo" @leave="hideInfo" :css="false">
            <div v-if="shippingFirstName && shippingFirstName !== ''" class="font-light text-coolGray-700 text-xs">{{text.name}}</div>
          </transition>
          <input id="shippingFirstName" name="shippingFirstName" v-model="shippingFirstName" type="text" :placeholder="text.name" class="w-full font-light appearance-none outline-none placeholder-coolGray-900 flex flex-row justify-center items-center" required/>
        </div>

        <!-- lastName -->
        <div class="relative mt-3 px-5 h-16 font-stolzl font-light w-full text-coolGray-900 border-2 border-coolGray-900 flex flex-col justify-center">
          <transition @enter="showInfo" @leave="hideInfo" :css="false">
            <div v-if="shippingLastName && shippingLastName !== ''" class="font-light text-coolGray-700 text-xs">{{text.lastName}}</div>
          </transition>
          <input id="shippingLastName" name="shippingLastName" v-model="shippingLastName" type="text" :placeholder="text.lastName" class="w-full font-light appearance-none outline-none placeholder-coolGray-900 flex flex-row justify-center items-center" required/>
        </div>
      </div>

      <!-- companyName -->
      <div class="relative mt-3 px-5 h-16 font-stolzl font-light w-full text-coolGray-900 border-2 border-coolGray-900 flex flex-col justify-center">
        <transition @enter="showInfo" @leave="hideInfo" :css="false">
          <div v-if="shippingCompanyName && shippingCompanyName !== ''" class="font-light text-coolGray-700 text-xs">{{text.company}}</div>
        </transition>
        <input id="shippingCompanyName" name="shippingCompanyName" v-model="shippingCompanyName" type="text" :placeholder="text.company" class="w-full font-light appearance-none outline-none placeholder-coolGray-900 flex flex-row justify-center items-center" />
      </div>

      <!-- streetAddress1 -->
      <div class="relative mt-3 px-5 h-16 font-stolzl font-light w-full text-coolGray-900 border-2 border-coolGray-900 flex flex-col justify-center">
        <transition @enter="showInfo" @leave="hideInfo" :css="false">
          <div v-if="shippingStreetAddress1 && shippingStreetAddress1 !== ''" class="font-light text-coolGray-700 text-xs">{{text.street}}</div>
        </transition>
        <input id="shippingStreetAddress1" name="shippingStreetAddress1" v-model="shippingStreetAddress1" type="text" :placeholder="text.street" class="w-full font-light appearance-none outline-none placeholder-coolGray-900 flex flex-row justify-center items-center" required/>
      </div>

      <!-- streetAddress2 -->
      <div class="relative mt-3 px-5 h-16 font-stolzl font-light w-full text-coolGray-900 border-2 border-coolGray-900 flex flex-col justify-center">
        <transition @enter="showInfo" @leave="hideInfo" :css="false">
          <div v-if="shippingStreetAddress2 && shippingStreetAddress2 !== ''" class="font-light text-coolGray-700 text-xs">{{text.street2}}</div>
        </transition>
        <input id="shippingStreetAddress2" name="shippingStreetAddress2" v-model="shippingStreetAddress2" type="text" :placeholder="text.street2" class="w-full font-light appearance-none outline-none placeholder-coolGray-900 flex flex-row justify-center items-center"/>
      </div>

      <div class="flex flex-row">
        <!-- postalCode -->
        <div class="mr-3 relative mt-3 px-5 h-16 font-stolzl font-light w-full text-coolGray-900 border-2 border-coolGray-900 flex flex-col justify-center">
          <transition @enter="showInfo" @leave="hideInfo" :css="false">
            <div v-if="shippingPostalCode && shippingPostalCode !== ''" class="font-light text-coolGray-700 text-xs">{{text.zip}}</div>
          </transition>
          <input id="shippingPostalCode" name="shippingPostalCode" v-model="shippingPostalCode" type="text" :placeholder="text.zip" class="w-full font-light appearance-none outline-none placeholder-coolGray-900 flex flex-row justify-center items-center" required/>
        </div>

        <!-- city -->
        <div class="relative mt-3 px-5 h-16 font-stolzl font-light w-full text-coolGray-900 border-2 border-coolGray-900 flex flex-col justify-center">
          <transition @enter="showInfo" @leave="hideInfo" :css="false">
            <div v-if="shippingCity && shippingCity !== ''" class="font-light text-coolGray-700 text-xs">{{text.city}}</div>
          </transition>
          <input id="shippingCity" name="shippingCity" v-model="shippingCity" type="text" :placeholder="text.city" class="w-full font-light appearance-none outline-none placeholder-coolGray-900 flex flex-row justify-center items-center" required/>
        </div>
      </div>

      <!-- cityArea -->
      <div class="relative mt-3 px-5 h-16 font-stolzl font-light w-full text-coolGray-900 border-2 border-coolGray-900 flex flex-col justify-center">
        <transition @enter="showInfo" @leave="hideInfo" :css="false">
          <div v-if="shippingCityArea && shippingCityArea !== ''" class="font-light text-coolGray-700 text-xs">{{text.region}}</div>
        </transition>
        <input id="shippingCityArea" name="shippingCityArea" v-model="shippingCityArea" type="text" :placeholder="text.region" class="w-full font-light appearance-none outline-none placeholder-coolGray-900 flex flex-row justify-center items-center"/>
      </div>

      <!-- shippingCountry -->
      <div class="relative mt-3 mr-1.5 px-5 h-16 font-stolzl font-light w-full text-coolGray-900 border-2 border-coolGray-900 flex flex-col justify-center">
        <select v-model="shippingCountry" class="w-full font-light outline-none placeholder-coolGray-900 flex flex-row justify-center items-center" required>
          <option v-for="country in countries" :key="country" :value="country.value">
            {{ translatedCountries[country.name] }}
          </option>
        </select>
      </div>

      <input id="submitUpdateShipping" type="submit" :value="text.changeShipping" class="transition duration-500 ease-in-out w-full mt-3 bg-coolGray-900 h-16 font-stolzl font-light text-white flex flex-row justify-center items-center border-coolGray-900 cursor-pointer hover:bg-white hover:text-coolGray-900 border-2"/>

      <div v-if="addressUpdateErrors?.length > 0" class="font-light text-base text-red-600">
        {{addressUpdateErrors}}
      </div>

    </form>

    <div class="font-stolzl flex flex-row justify-start items-center mt-2 -ml-1 group cursor-pointer" @click="editMode = false; currentEdit = ''">
      <svg xmlns="http://www.w3.org/2000/svg" class="transition duration-200 ease-out h-4 w-4 text-coolGray-700 transform group-hover:text-coolGray-900 group-hover:-translate-x-1" fill="none" viewBox="0 0 24 24" stroke="currentColor">
        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 19l-7-7 7-7" />
      </svg>
      <div class="transition duration-200 ease-out font-light text-base md:text-sm text-coolGray-700 group-hover:text-coolGray-900 mt-0.5 ml-0.5">{{text.back2}}</div>
    </div>

  </div>

  <!-- Rechnungsadresse Forumlar -->
  <div v-if="editMode && currentEdit == 'billingAddress'" class="w-full">

    <form id="data05" @submit.prevent="submitUpdateBilling">

      <div class="flex flex-row">
        <!-- firstName -->
        <div class="relative mr-3 mt-3 px-5 h-16 font-stolzl font-light w-full text-coolGray-900 border-2 border-coolGray-900 flex flex-col justify-center">
          <transition @enter="showInfo" @leave="hideInfo" :css="false">
            <div v-if="billingFirstName && billingFirstName !== ''" class="font-light text-coolGray-700 text-xs">{{text.name}}</div>
          </transition>
          <input id="billingFirstName" name="billingFirstName" v-model="billingFirstName" type="text" :placeholder="text.name" class="w-full font-light appearance-none outline-none placeholder-coolGray-900 flex flex-row justify-center items-center" required/>
        </div>

        <!-- lastName -->
        <div class="relative mt-3 px-5 h-16 font-stolzl font-light w-full text-coolGray-900 border-2 border-coolGray-900 flex flex-col justify-center">
          <transition @enter="showInfo" @leave="hideInfo" :css="false">
            <div v-if="billingLastName && billingLastName !== ''" class="font-light text-coolGray-700 text-xs">{{text.lastName}}</div>
          </transition>
          <input id="billingLastName" name="billingLastName" v-model="billingLastName" type="text" :placeholder="text.lastName" class="w-full font-light appearance-none outline-none placeholder-coolGray-900 flex flex-row justify-center items-center" required/>
        </div>
      </div>

      <!-- companyName -->
      <div class="relative mt-3 px-5 h-16 font-stolzl font-light w-full text-coolGray-900 border-2 border-coolGray-900 flex flex-col justify-center">
        <transition @enter="showInfo" @leave="hideInfo" :css="false">
          <div v-if="billingCompanyName && billingCompanyName !== ''" class="font-light text-coolGray-700 text-xs">{{text.company}}</div>
        </transition>
        <input id="billingCompanyName" name="billingCompanyName" v-model="billingCompanyName" type="text" :placeholder="text.company" class="w-full font-light appearance-none outline-none placeholder-coolGray-900 flex flex-row justify-center items-center"/>
      </div>

      <!-- streetAddress1 -->
      <div class="relative mt-3 px-5 h-16 font-stolzl font-light w-full text-coolGray-900 border-2 border-coolGray-900 flex flex-col justify-center">
        <transition @enter="showInfo" @leave="hideInfo" :css="false">
          <div v-if="billingStreetAddress1 && billingStreetAddress1 !== ''" class="font-light text-coolGray-700 text-xs">{{text.street}}</div>
        </transition>
        <input id="billingStreetAddress1" name="billingStreetAddress1" v-model="billingStreetAddress1" type="text" :placeholder="text.street" class="w-full font-light appearance-none outline-none placeholder-coolGray-900 flex flex-row justify-center items-center" required/>
      </div>

      <!-- streetAddress2 -->
      <div class="relative mt-3 px-5 h-16 font-stolzl font-light w-full text-coolGray-900 border-2 border-coolGray-900 flex flex-col justify-center">
        <transition @enter="showInfo" @leave="hideInfo" :css="false">
          <div v-if="billingStreetAddress2 && billingStreetAddress2 !== ''" class="font-light text-coolGray-700 text-xs">{{text.street2}}</div>
        </transition>
        <input id="billingStreetAddress2" name="billingStreetAddress2" v-model="billingStreetAddress2" type="text" :placeholder="text.street2" class="w-full font-light appearance-none outline-none placeholder-coolGray-900 flex flex-row justify-center items-center"/>
      </div>

      <div class="flex flex-row">
        <!-- postalCode -->
        <div class="mr-3 relative mt-3 px-5 h-16 font-stolzl font-light w-full text-coolGray-900 border-2 border-coolGray-900 flex flex-col justify-center">
          <transition @enter="showInfo" @leave="hideInfo" :css="false">
            <div v-if="billingPostalCode && billingPostalCode !== ''" class="font-light text-coolGray-700 text-xs">{{text.zip}}</div>
          </transition>
          <input id="billingPostalCode" name="billingPostalCode" v-model="billingPostalCode" type="text" :placeholder="text.zip" class="w-full font-light appearance-none outline-none placeholder-coolGray-900 flex flex-row justify-center items-center" required/>
        </div>

        <!-- city -->
        <div class="relative mt-3 px-5 h-16 font-stolzl font-light w-full text-coolGray-900 border-2 border-coolGray-900 flex flex-col justify-center">
          <transition @enter="showInfo" @leave="hideInfo" :css="false">
            <div v-if="billingCity && billingCity !== ''" class="font-light text-coolGray-700 text-xs">{{text.city}}</div>
          </transition>
          <input id="billingCity" name="billingCity" v-model="billingCity" type="text" :placeholder="text.city" class="w-full font-light appearance-none outline-none placeholder-coolGray-900 flex flex-row justify-center items-center" required/>
        </div>
      </div>

      <!-- cityArea -->
      <div class="relative mt-3 px-5 h-16 font-stolzl font-light w-full text-coolGray-900 border-2 border-coolGray-900 flex flex-col justify-center">
        <transition @enter="showInfo" @leave="hideInfo" :css="false">
          <div v-if="billingCityArea && billingCityArea !== ''" class="font-light text-coolGray-700 text-xs">{{text.region}}</div>
        </transition>
        <input id="billingCityArea" name="billingCityArea" v-model="billingCityArea" type="text" :placeholder="text.region" class="w-full font-light appearance-none outline-none placeholder-coolGray-900 flex flex-row justify-center items-center"/>
      </div>

      <!-- country -->
      <div class="relative mt-3 mr-1.5 px-5 h-16 font-stolzl font-light w-full text-coolGray-900 border-2 border-coolGray-900 flex flex-col justify-center">
        <select v-model="billingCountry" class="w-full font-light outline-none placeholder-coolGray-900 flex flex-row justify-center items-center" required>
          <option v-for="country in countries" :key="country" :value="country.value">
            {{ translatedCountries[country.name] }}
          </option>
        </select>
      </div>

      <input id="submitUpdateBilling" type="submit" :value="text.changeBilling" class="transition duration-500 ease-in-out w-full mt-3 bg-coolGray-900 h-16 font-stolzl font-light text-white flex flex-row justify-center items-center border-coolGray-900 cursor-pointer hover:bg-white hover:text-coolGray-900 border-2"/>

      <div v-if="addressUpdateErrors?.length > 0" class="font-light text-base text-red-600">
        {{addressUpdateErrors}}
      </div>

    </form>

    <div class="font-stolzl flex flex-row justify-start items-center mt-2 -ml-1 group cursor-pointer" @click="editMode = false; currentEdit = ''">
      <svg xmlns="http://www.w3.org/2000/svg" class="transition duration-200 ease-out h-4 w-4 text-coolGray-700 transform group-hover:text-coolGray-900 group-hover:-translate-x-1" fill="none" viewBox="0 0 24 24" stroke="currentColor">
        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 19l-7-7 7-7" />
      </svg>
      <div class="transition duration-200 ease-out font-light text-base md:text-sm text-coolGray-700 group-hover:text-coolGray-900 mt-0.5 ml-0.5">{{text.back2}}</div>
    </div>

  </div>

  <!-- Adressen bearbeiten Buttons -->
  <div v-if="!editMode">
    <div class="transition duration-500 ease-in-out w-full mt-5 my-1.5 bg-coolGray-900 h-16 font-stolzl font-light text-white flex flex-row justify-center items-center border-coolGray-900 cursor-pointer hover:bg-white hover:text-coolGray-900 border-2"
        @click="editMode = true; currentEdit = 'shippingAddress'">{{text.editShipping}}</div>
    <div class="transition duration-500 ease-in-out w-full my-1.5 bg-coolGray-900 h-16 font-stolzl font-light text-white flex flex-row justify-center items-center border-coolGray-900 cursor-pointer hover:bg-white hover:text-coolGray-900 border-2"
        @click="editMode = true; currentEdit = 'billingAddress'">{{text.editBilling}}</div>
  </div>

  <!-- GraphQL Components -->
  <updateAddressMutation />
  <createAddressMutation />

</template>

<script>
// Mixins
import showHide from '../mixins/showHide.vue';
import forceLogout from '../mixins/forceLogout.vue';
import silentRefresh from '../mixins/silentRefresh.vue';

// GraphQL
import createAddressMutation from '../graphql/saleor/createAddressMutation.vue';
import updateAddressMutation from '../graphql/saleor/updateAddressMutation.vue';

export default {
  name: 'account_userDetails',
  components: { createAddressMutation, updateAddressMutation },
  mixins: [showHide, forceLogout, silentRefresh],

  data() {
    return {
      errors: null,
      editMode: false,
      currentEdit: '',

      // update shipping address
      addressUpdateErrors: null,
      shippingFirstName: null,
      shippingLastName: null,
      shippingCompanyName: null,
      shippingStreetAddress1: null,
      shippingStreetAddress2: null,
      shippingPostalCode: null,
      shippingCity: null,
      shippingCityArea: null,
      shippingCountry: null,

      // update billing address
      billingFirstName: null,
      billingLastName: null,
      billingCompanyName: null,
      billingStreetAddress1: null,
      billingStreetAddress2: null,
      billingPostalCode: null,
      billingCity: null,
      billingCityArea: null,
      billingCountry: null,

    };
  },

  computed: {
    // graphQl
    userDetails() {
      return this.$store.state.data.userDetails?.me;
    },
    createAddress() {
      return this.$store.state.data.createAddress;
    },
    updateAddress() {
      return this.$store.state.data.updateAddress;
    },
    getUserDetails() {
      return this.$store.state.data.getUserDetails;
    },

    // store
    text() {
      return this.$store.state.lang.account[this.$store.state.lang.currentLang];
    },
    countries() {
      return this.$store.state.data.countries;
    },
    translatedCountries() {
      return this.$store.state.lang.countries[this.$store.state.lang.currentLang];
    },
  },

  mounted() {
    if (this.userDetails.defaultShippingAddress) {
      this.shippingFirstName = this.userDetails.defaultShippingAddress.firstName;
      this.shippingLastName = this.userDetails.defaultShippingAddress.lastName;
      this.shippingCompanyName = this.userDetails.defaultShippingAddress.companyName;
      this.shippingStreetAddress1 = this.userDetails.defaultShippingAddress.streetAddress1;
      this.shippingStreetAddress2 = this.userDetails.defaultShippingAddress.streetAddress2;
      this.shippingPostalCode = this.userDetails.defaultShippingAddress.postalCode;
      this.shippingCity = this.userDetails.defaultShippingAddress.city;
      this.shippingCityArea = this.userDetails.defaultShippingAddress.cityArea;
      this.shippingCountry = this.userDetails.defaultShippingAddress.country.code;
    }
    if (this.userDetails.defaultBillingAddress) {
      this.billingFirstName = this.userDetails.defaultBillingAddress.firstName;
      this.billingLastName = this.userDetails.defaultBillingAddress.lastName;
      this.billingCompanyName = this.userDetails.defaultBillingAddress.companyName;
      this.billingStreetAddress1 = this.userDetails.defaultBillingAddress.streetAddress1;
      this.billingStreetAddress2 = this.userDetails.defaultBillingAddress.streetAddress2;
      this.billingPostalCode = this.userDetails.defaultBillingAddress.postalCode;
      this.billingCity = this.userDetails.defaultBillingAddress.city;
      this.billingCityArea = this.userDetails.defaultBillingAddress.cityArea;
      this.billingCountry = this.userDetails.defaultBillingAddress.country.code;
    }
  },

  methods: {

    getCountryName(countryCode) {
      const index = this.countries.findIndex((c) => c.value === countryCode);
      let result = '-';
      if (index !== -1) {
        if (this.countries[index].name !== '') {
          result = this.countries[index].name;
        }
      }
      return result;
    },

    updateAddressMethod(address, elementId) {
      this.updateAddress(address)
        .then((result) => {
          if (result.error) {
            console.log(result.error.message);
            if (result.error.message.includes('expired')) {
              const el = document.getElementById(elementId);
              if (el) {
                this.silentRefreshTryAgain(el);
              } else {
                this.forceLogout();
              }
            } else {
              this.addressUpdateErrors = result.error;
            }
          } else {
            const errors = result.data.accountAddressUpdate.accountErrors;
            if (errors.length === 0) {
              this.getUserDetails();
              this.addressUpdateErrors = null;
              this.editMode = false;
              this.currentEdit = '';
            } else {
              this.addressUpdateErrors = errors;
            }
          }
        });
    },

    createAddressMethod(address, elementId) {
      this.createAddress(address).then((result) => {
        if (result.error) {
          console.log(result.error.message);
          if (result.error.message.includes('expired')) {
            const el = document.getElementById(elementId);
            if (el) {
              this.silentRefreshTryAgain(el);
            } else {
              this.forceLogout();
            }
          } else {
            this.addressUpdateErrors = result.error;
          }
        } else {
          console.log(result.data);
          const errors = result.data.accountAddressCreate.accountErrors;
          if (errors.length === 0) {
            this.getUserDetails();
            this.addressUpdateErrors = null;
            this.editMode = false;
            this.currentEdit = '';
          } else {
            this.addressUpdateErrors = errors;
          }
        }
      });
    },

    submitUpdateShipping() {
      if (this.userDetails.defaultShippingAddress) {
        this.updateAddressMethod({
          id: this.userDetails.defaultShippingAddress.id,
          firstName: this.shippingFirstName,
          lastName: this.shippingLastName,
          companyName: this.shippingCompanyName,
          streetAddress1: this.shippingStreetAddress1,
          streetAddress2: this.shippingStreetAddress2,
          city: this.shippingCity,
          cityArea: this.shippingCityArea,
          postalCode: this.shippingPostalCode,
          country: this.shippingCountry,
        }, 'submitUpdateShipping');
      } else {
        this.createAddressMethod({
          type: 'SHIPPING',
          firstName: this.shippingFirstName || '',
          lastName: this.shippingLastName || '',
          companyName: this.shippingCompanyName || '',
          streetAddress1: this.shippingStreetAddress1 || '',
          streetAddress2: this.shippingStreetAddress2 || '',
          city: this.shippingCity || '',
          cityArea: this.shippingCityArea || '',
          postalCode: this.shippingPostalCode || '',
          country: this.shippingCountry || '',
        }, 'submitUpdateShipping');
      }
    },

    submitUpdateBilling() {
      if (this.userDetails.defaultBillingAddress) {
        this.updateAddressMethod({
          id: this.userDetails.defaultBillingAddress.id,
          firstName: this.billingFirstName,
          lastName: this.billingLastName,
          companyName: this.billingCompanyName,
          streetAddress1: this.billingStreetAddress1,
          streetAddress2: this.billingStreetAddress2,
          city: this.billingCity,
          cityArea: this.billingCityArea,
          postalCode: this.billingPostalCode,
          country: this.billingCountry,
        }, 'submitUpdateBilling');
      } else {
        this.createAddressMethod({
          type: 'BILLING',
          firstName: this.billingFirstName || '',
          lastName: this.billingLastName || '',
          companyName: this.billingCompanyName || '',
          streetAddress1: this.billingStreetAddress1 || '',
          streetAddress2: this.billingStreetAddress2 || '',
          city: this.billingCity || '',
          cityArea: this.billingCityArea || '',
          postalCode: this.billingPostalCode || '',
          country: this.billingCountry || '',
        }, 'submitUpdateBilling');
      }
    },

  },
};

</script>
